<template>
  <fragment>
    <router-link
      v-if="canEnterActivity"
      v-slot="{ href, navigate }"
      :to="itemLink"
    >
      <a
        class="activity-item"
        :href="href"
        @click="navigate"
      >
        <activity-item-content
          :activity="activity"
          :current-status="currentStatus"
        />
      </a>
    </router-link>
    <div
      v-else
      class="activity-item"
    >
      <activity-item-content
        :activity="activity"
        :current-status="currentStatus"
      />
      <div
        v-if="activity.users_can_participate"
        class="activity-actions"
      >
        <action-icon-button
          :to="interactionsLink"
          :title="'Respuestas'"
          :icon="'responses'"
        />
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

import sharedConstants from '@/shared/constants.json'
import ActionIconButton from '@/components/ActionIconButton.vue'
import ActivityItemContent from './ActivityItemContent.vue'

export default {
  name: 'ActivityItem',
  components: {
    ActivityItemContent,
    ActionIconButton
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('participationProject', ['projectData']),
    participation() {
      return (
        this.activity.participations && this.activity.participations.length > 0
          ? this.activity.participations[0] : null
      )
    },
    currentStatus() {
      return this.participation ? this.participation.status : sharedConstants.PARTICIPATION_STATUS.NOT_STARTED
    },
    interactionsLink() {
      return {
        name: 'project-interactions-filtered',
        params: { projectIdentifier: this.projectData.identifier, activityIdentifier: this.activity.identifier }
      }
    },
    canEnterActivity() {
      return this.activity.users_can_participate && [
        sharedConstants.PARTICIPATION_STATUS.NOT_STARTED, sharedConstants.PARTICIPATION_STATUS.IN_PROGRESS
      ].includes(this.currentStatus)
    },
    itemLink() {
      if (!this.canEnterActivity) return null

      return {
        name: 'participation-activity',
        params: { identifier: this.projectData.identifier, activityIdentifier: this.activity.identifier }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-item {
  color: #000;
  display: block;
  background: #fff;
  box-shadow: 0px 4px 6px 0px #C8C8C83D;
  border: 1px solid #E1E1E1;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 25px;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }
}

.activity-item-heading {
  display: flex;
  justify-content: space-between;
}

h3 {
  font-size: 18px;
  font-weight: 500;
}

.activity-actions {
  display: flex;
  justify-content: flex-end;

  a:not(:first-child) {
    margin-left: 12px;
  }
}
</style>
