<template>
  <div>
    <div :class="{ 'activity-item-heading': true, 'has-dates': displayDate }">
      <span
        v-if="displayDate"
        class="activity-schedule"
      >
        {{ displayDate }}
      </span>
      <activity-status-indication :status="currentStatus" />
    </div>
    <h3>{{ activity.name }}</h3>
  </div>
</template>

<script>
import ActivityStatusIndication from './ActivityStatusIndication.vue'
import { formatActivityDates } from '../../dates'

export default {
  name: 'ActivityItemContent',
  components: {
    ActivityStatusIndication
  },
  props: {
    activity: {
      type: Object,
      required: true
    },
    currentStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    displayDate() {
      const {
        participation_enabled_from: participationEnabledFrom,
        participation_enabled_to: participationEnabledTo
      } = this.activity

      return formatActivityDates(participationEnabledFrom, participationEnabledTo)
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 18px;
  font-weight: 500;
}

.activity-item {
  color: #000;
  display: block;
  background: #fff;
  box-shadow: 0px 4px 6px 0px #C8C8C83D;
  border: 1px solid #E1E1E1;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.activity-item-heading {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;

  &.has-dates {
    justify-content: space-between;
  }
}
</style>
