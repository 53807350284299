<template>
  <div>
    <navigation-bar
      :background="'white'"
      :link="{ name: 'project-detail', params: { identifier: projectData.identifier } }"
      :label="projectData.name"
    />
    <div class="container is-widescreen page-bottom-padding">
      <div class="wrapper">
        <div class="columns is-centered">
          <div class="column standard-column">
            <div class="community-link-buttons">
              <custom-link-button
                v-if="displayViewResponsesButton"
                button-text="Ver respuestas de otros participantes"
                icon-image="project/icon-responses.svg"
                :to="interactionsLink"
                :display-arrow="true"
                class="view-responses-button"
              />
              <custom-link-button
                v-if="displayForum"
                button-text="Foro"
                icon-image="project/edit.svg"
                :to="forumLink"
                :display-arrow="false"
              />
            </div>
            <pending-activities-carousel :activities="pendingActivities" />
            <div class="two-columns">
              <div class="column-activities">
                <h2>Todas las actividades</h2>
                <activity-listing :activities="activitiesToDisplay" />
                <div
                  v-if="!allActivitiesDisplayed"
                  class="centered-button-container"
                >
                  <grey-button :click="displayAllActivities">
                    Ver todo
                  </grey-button>
                </div>
              </div>
              <div
                v-if="displayForum"
                class="forum-column-container"
              >
                <h2>Foro</h2>
                <thread-listing
                  :detail-route-name="'participant-forum-thread'"
                  :limit="2"
                  :slim="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { formatDate } from '@/dates'
import ActivityListing from '@/components/activity-listing/ActivityListing'
import GreyButton from '@/components/buttons/GreyButton.vue'
import ThreadListing from '@/components/forum/ThreadListing'
import NavigationBar from '@/components/NavigationBar'
import CustomLinkButton from '@/components/CustomLinkButton'
import sharedConstants from '@/shared/constants.json'
import PendingActivitiesCarousel from '../../pending-activities-carousel/PendingActivitiesCarousel.vue'

export default {
  name: 'ParticipationViewContentContainer',
  components: {
    ActivityListing,
    CustomLinkButton,
    GreyButton,
    NavigationBar,
    PendingActivitiesCarousel,
    ThreadListing
  },
  data() {
    return {
      loading: true,
      showAllActivities: false
    }
  },
  computed: {
    ...mapGetters('participationProject', ['isCommunityProject', 'pendingActivities']),
    ...mapGetters('forum', ['displayForum']),
    ...mapState('participationProject', ['projectData']),
    ...mapState('participationParticipation', ['participationData']),
    formattedStartDate() {
      return formatDate(this.projectData.start_date)
    },
    formattedFinishDate() {
      return formatDate(this.projectData.finish_date)
    },
    interactionsLink() {
      return { name: 'project-interactions', params: { projectIdentifier: this.projectData.identifier } }
    },
    forumLink() {
      return { name: 'participant-forum', params: { projectIdentifier: this.projectData.identifier } }
    },
    displayViewResponsesButton() {
      return this.projectData.activities.some(
        (activity) => (
          activity.participations
          && activity.participations.length > 0
          && activity.participations[0].status === sharedConstants.PARTICIPATION_STATUS.COMPLETE
        )
      )
    },
    activitiesToDisplay() {
      return this.showAllActivities ? this.projectData.activities : this.projectData.activities.slice(0, 3)
    },
    allActivitiesDisplayed() {
      return this.showAllActivities || this.projectData.activities.length <= 3
    }
  },
  methods: {
    displayAllActivities() {
      this.showAllActivities = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../scss/community.scss';
</style>
