<template>
  <div :class="containerClass">
    <template v-if="activities.length > 0">
      <div class="carousel-heading">
        <h2>Actividades sin completar</h2>
      </div>
      <VueSlickCarousel
        ref="slick"
        v-bind="carouselSettings"
      >
        <pending-activity-card
          v-for="activity in activities"
          :key="activity.identifier"
          :activity-data="activity"
        />
        <template #prevArrow>
          <span
            class="arrow arrow-previous"
          >
            <arrow-right />
          </span>
        </template>
        <template #nextArrow>
          <span
            class="arrow arrow-next"
          >
            <arrow-right />
          </span>
        </template>
      </VueSlickCarousel>
    </template>
    <div
      v-else
      class="no-pending-activities-message"
    >
      <img
        src="../../assets/completed-checklist.svg"
        alt=""
      >
      <h2>¡Ya no tienes actividades pendientes!</h2>
      <template v-if="displayForum">
        <p>Entra al Foro para saber qué piensan otros participantes y deja tus comentarios.</p>
        <blue-button
          :size="'large'"
          :to="forumLink"
        >
          Ir al Foro
        </blue-button>
      </template>
      <template v-else>
        <p>Entra al Stream para saber qué piensan otros participantes y deja tus comentarios.</p>
        <blue-button
          :size="'large'"
          :to="interactionsLink"
        >
          Ir al Stream
        </blue-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import ArrowRight from '../icons/ArrowRight.vue'
import BlueButton from '../buttons/BlueButton.vue'
import PendingActivityCard from './PendingActivityCard.vue'

export default {
  name: 'PendingActivitiesCarousel',
  components: {
    ArrowRight,
    BlueButton,
    PendingActivityCard,
    VueSlickCarousel
  },
  props: {
    activities: {
      type: Array,
      default: Array
    }
  },
  computed: {
    ...mapGetters('forum', ['displayForum']),
    ...mapState('participationProject', ['projectData']),
    carouselSettings() {
      return {
        arrows: true,
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    },
    containerClass() {
      return `pending-activities-carousel${this.activities.length === 0 ? ' no-pending-activities' : ''}`
    },
    forumLink() {
      return { name: 'participant-forum', params: { projectIdentifier: this.projectData.identifier } }
    },
    interactionsLink() {
      return { name: 'project-interactions', params: { projectIdentifier: this.projectData.identifier } }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .slick-slider {
  min-width: 100%;
  width: 0;
  overflow: hidden;
}

.pending-activities-carousel {
  background: #e3edfb;
  height: 320px;
  margin-bottom: 30px;

  .slick-slider {
    overflow: visible;
  }

  &.no-pending-activities {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
  }
}

.carousel-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 15px 0 15px;
  margin-bottom: 25px;

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
}

.carousel-controls {
  display: flex;

}

.arrow {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #dbe7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #266df6;
  cursor: pointer;
  transition: $buttonBackgroundTransition;
  position: absolute;
  top: -38px;

  &:before {
    display: none;
  }

  &.slick-disabled {
    opacity: 0.6;
    cursor: auto;
  }

  &:not(.slick-disabled):hover {
    background: #266df6;
    color: #fff;
  }

  &.arrow-previous {
    left: auto;

    svg {
      transform: rotate(180deg);
    }
  }
}

.no-pending-activities-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 134px;
    display: block;
    margin-bottom: 12px;
  }

  h2 {
    font-weight: 600;
    color: $blue;
    font-size: 18px;
    line-height: 23px;
    max-width: 210px;
    margin-bottom: 14px;
    text-align: center;
  }

  p {
    font-size: 14px;
    text-align: center;
    max-width: 300px;
    margin-bottom: 18px;
  }
}

@include respond(mobile-up) {
  .pending-activity-card {
    width: 320px !important;
    height: 220px;
    margin-left: 16px;
  }

  .arrow {
    &.arrow-previous {
      right: 65px;
    }

    &.arrow-next {
      right: 25px;
    }
  }
}

@include respond(mobile) {
  .pending-activity-card {
    max-width: calc(100vw - 80px);
    width: 320px !important;
    height: 220px;
    margin-left: 16px;
  }

  .arrow {
    &.arrow-previous {
      right: 59px;
    }

    &.arrow-next {
      right: 19px;
    }
  }
}
</style>
