<template>
  <div class="pending-activity-card white-box">
    <div class="activity-schedule">
      {{ displayDate }}
    </div>
    <div class="activity-name">
      {{ activityData.name }}
    </div>
    <div
      v-snip
      class="activity-description"
    >
      {{ activityData.description|stripTags }}
    </div>
    <div
      v-if="activityData.users_can_participate"
      class="activity-action"
    >
      <blue-button :to="cardLink">
        Comenzar
      </blue-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { formatActivityDates } from '../../dates'
import BlueButton from '../buttons/BlueButton.vue'

export default {
  name: 'PendingActivityCard',
  components: {
    BlueButton
  },
  props: {
    activityData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('participationProject', ['projectData']),
    displayDate() {
      const {
        participation_enabled_from: participationEnabledFrom,
        participation_enabled_to: participationEnabledTo
      } = this.activityData

      return formatActivityDates(participationEnabledFrom, participationEnabledTo)
    },
    cardLink() {
      return {
        name: 'participation-activity',
        params: { identifier: this.projectData.identifier, activityIdentifier: this.activityData.identifier }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pending-activity-card {
  padding: 21px;
  background: #fff;
  display: flex !important;
  flex-direction: column;
}

.activity-schedule {
  margin-bottom: 16px;
}

.activity-name {
  font-weight: 600;
  margin-bottom: 4px;
}

.activity-description {
  font-size: 14px;
  color: #979797;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 18px;
  line-height: 22.5px;
  max-height: 94px;
}
</style>
