<template>
  <pill :color="pillColor">
    {{ statusText }}
  </pill>
</template>

<script>
import { PARTICIPATION_STATUS } from '@/shared/constants.json'

import Pill, { pillColors } from '../Pill.vue'

export default {
  name: 'ActivityStatusIndication',
  components: {
    Pill
  },
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    pillColor() {
      switch (this.status) {
        case PARTICIPATION_STATUS.NOT_STARTED:
          return pillColors.yellow
        case PARTICIPATION_STATUS.IN_PROGRESS:
          return pillColors.blue
        default: // PARTICIPATION_STATUS.COMPLETED mainly (disqualified shouldn't be displayed)
          return pillColors.green
      }
    },
    statusText() {
      switch (this.status) {
        case PARTICIPATION_STATUS.NOT_STARTED:
          return 'Sin completar'
        case PARTICIPATION_STATUS.IN_PROGRESS:
          return 'En curso'
        default: // PARTICIPATION_STATUS.COMPLETED mainly (disqualified shouldn't be displayed)
          return 'Completado'
      }
    }
  }
}
</script>
