<template>
  <participation-view-content-container
    :current-stage="currentStage"
    :grey-background="true"
    :class="containerClass"
  >
    <!-- Only rendered in community type projects -->
    <community-project-home v-if="isCommunityProject" />
  </participation-view-content-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import CommunityProjectHome from '@/components/participation/communities/CommunityProjectHome.vue'
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import participationStages from '@/components/participation/participationStages'

export default {
  name: 'ProjectHome',
  components: {
    CommunityProjectHome,
    ParticipationViewContentContainer
  },
  computed: {
    ...mapGetters('participationProject', ['isCommunityProject']),
    ...mapGetters('forum', ['displayForum']),
    ...mapState('participationParticipant', ['participantData']),
    ...mapState('participationProject', ['projectData']),
    containerClass() {
      return this.displayForum ? 'extra-wide' : ''
    }
  },
  created() {
    this.currentStage = participationStages.projectHome
  }
}
</script>
