<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="to"
    :class="buttonClass"
  >
    <a
      :href="href"
      @click="navigate"
    >
      <slot />
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'BlueButton',
  props: {
    size: {
      type: String,
      default: 'small'
    },
    to: {
      type: Object,
      required: true
    }
  },
  computed: {
    buttonClass() {
      return `blue-button size-${this.size}`
    }
  }
}
</script>

<style lang="scss" scoped>
.blue-button {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  height: 35px;
  line-height: 35px;
  white-space: nowrap;
  display: inline-block;
  padding: 0 32px;
  background: #0a6cff;
  border-radius: 17.5px;
  box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.3);
  transition: $buttonBackgroundTransition;

  &:hover {
    background: darken(#0a6cff, 10);
  }

  &.size-large {
    height: 44px;
    line-height: 44px;
    border-radius: 22px;
    font-size: 16px;
    padding: 0 55px;
  }
}
</style>
