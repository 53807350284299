<template>
  <div :class="['pill', `color-${color}`]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Pill',
  props: {
    color: {
      type: String,
      required: true
    }
  }
}

export const pillColors = {
  red: 'red',
  yellow: 'yellow',
  blue: 'blue',
  green: 'green'
}
</script>

<style lang="scss" scoped>
.pill {
  display: inline-block;
  background-color: #fdedd3;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 600;
  color: $orange;
  border-radius: 15px;
  width: 96px;
  text-align: center;

  &.color-blue {
    color: $blue;
    background-color: #cee2ff;
  }

  &.color-green {
    color: $green;
    background-color: #d8f5e3;
  }

  &.color-yellow {
    color: $orange;
    background-color: $yellow;
  }
}
</style>
