<template>
  <div>
    <activity-item
      v-for="activity in activities"
      :key="activity.id"
      :activity="activity"
    />
  </div>
</template>

<script>
import ActivityItem from './ActivityItem.vue'

export default {
  name: 'ActivityListing',
  components: {
    ActivityItem
  },
  props: {
    activities: {
      type: Array,
      required: true
    }
  }
}
</script>
